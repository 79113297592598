@media print {
  /* Inhaltsbreite setzen, Floats und Margins aufheben */
  /* Achtung: Die Klassen und IDs variieren von Theme zu Theme. Hier also eigene Klassen setzen */
  #content,
  #page {
    width: 100%;
    margin: 0;
    float: none;
  }
  /** Seitenränder einstellen */
  @page {
    margin: 2cm;
  }
  /* Font auf 16px/13pt setzen, Background auf Weiß und Schrift auf Schwarz setzen.*/
  /* Das spart Tinte */
  body {
    font-family: "foco";
    font-weight: 400;
    line-height: 1.3;
    background: #FFFFFF !important;
    color: #000000;
  }
  h1 {
    font-size: 24pt;
  }
  h2,
  h3,
  h4,
  h5 {
    font-size: 14pt;
    margin-top: 25px;
  }
  /* Alle Seitenumbrüche definieren */
  a {
    page-break-inside: avoid;
  }
  blockquote {
    page-break-inside: avoid;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
    page-break-inside: avoid;
  }
  img {
    page-break-inside: avoid;
    page-break-after: avoid;
  }
  table,
  pre {
    page-break-inside: avoid;
  }
  ul,
  ol,
  dl {
    page-break-before: avoid;
  }
  /* Linkfarbe und Linkverhalten darstellen */
  a:link,
  a:visited,
  a {
    background: transparent;
    font-weight: bold;
    text-decoration: none;
    text-align: left;
  }
  a {
    page-break-inside: avoid;
  }
  a.keywordHighlight::after {
    content: " " !important;
  }
  h3 > a[href*="://"]::after,
  a:not([href^="/"])::after {
    content: "\00000a" attr(href);
    white-space: pre;
    text-decoration: none;
    font-size: 0.75em;
  }
  h3 > a[href^="/"]:not([href*="://"])::after {
    content: "\00000a" var(--base-url) attr(href);
    white-space: pre;
    text-decoration: none;
    font-size: 0.75em;
  }
  /*
	$a::after > img {
		content: "fff" !important;
	}
	*/
  /**
	* Eingebundene Videos verschwinden lassen und den Whitespace der iframes auf null reduzieren.
	*/
  .entry iframe,
  ins {
    display: none;
    width: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
    line-height: 0pt !important;
    white-space: nowrap;
  }
  .embed-youtube,
  .embed-responsive {
    position: absolute;
    height: 0;
    overflow: hidden;
  }
  /* Unnötige Elemente ausblenden für den Druck */
  #header-widgets,
  nav,
  aside.mashsb-container,
  .sidebar,
  .mashshare-top,
  .mashshare-bottom,
  .content-ads,
  .make-comment,
  .author-bio,
  .heading,
  .related-posts,
  #decomments-form-add-comment,
  #breadcrumbs,
  #footer,
  .post-byline,
  .meta-single,
  .site-title img,
  .post-tags,
  .readability {
    display: none!important;
  }
  /* Wichtige Elemente definieren */
  p,
  address,
  li,
  dt,
  dd,
  blockquote {
    font-size: 100%;
  }
  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  /* Zeichensatz fuer Code Beispiele */
  code,
  pre {
    font-family: "Courier New", Courier, mono;
  }
  ul,
  ol {
    list-style: square;
    margin-left: 18pt;
    margin-bottom: 20pt;
  }
  li {
    line-height: 1.6em;
  }
  /* Seitenspezifische Elemente ausblenden */
  #serviceNavi-top-wrapper,
  .burgerMenu,
  #flyout,
  .container.ad,
  .carousel,
  img[src*="/image/locked/"],
  #searchOverlay,
  #back-to-top,
  .breadcrumb-container,
  .gdrpModal,
  .d-print-none,
  .registerFromWrapper,
  .btn-back,
  .linkToMore {
    display: none !important;
  }
  /* Logo */
  #logo img {
    height: 3rem;
    width: auto;
  }
  h1,
  .mainHeadline {
    font-family: "foco";
    font-weight: 300;
    font-size: 2.5rem;
    width: 100%;
    margin: 20px auto;
    overflow: hidden;
    text-align: left;
    line-height: 1.2em;
  }
  /* aktuelle Studien */
  .studienbox {
    clear: both;
  }
  /* Artikelbox */
  .article-box-small {
    padding: 0 0 20px 0;
  }
  .article-box-small p {
    margin: 0;
  }
  .article-box-small .article-subtitle {
    font-family: "foco";
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.125rem;
  }
  .article-box-small h3 {
    font-family: "foco";
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1;
    margin-bottom: 10px;
    margin-top: 0;
  }
  .article-box-small h5 {
    font-family: "foco";
    font-weight: 400;
    font-size: 1rem;
    line-height: 1;
    margin-bottom: 10px;
    margin-top: 0;
  }
  .article-box-small .summary {
    font-weight: 400;
    font-size: 1rem;
    font-family: "foco";
    font-weight: 300;
    line-height: 1.125rem;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .article-box-small .moreLink {
    display: none;
  }
  section.topNews {
    clear: both;
    display: inline-block;
  }
  section.topNews:nth-of-type(1)::before {
    content: "Top News";
    font-family: "foco";
    font-weight: 300;
    font-size: 2.5rem;
    width: 100%;
    margin: 20px auto;
    overflow: hidden;
    text-align: left;
    line-height: 1.2em;
  }
  section.topNews .newsblock-image {
    display: inline-block;
    float: left;
  }
  section.topNews .newsblock-image img {
    max-height: 12rem;
    max-width: 12rem;
    margin-right: 1rem;
    height: auto;
    width: auto;
  }
  section.topNews .newsblock-headline {
    font-family: "foco";
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.125rem;
  }
  section.topNews .newsblock-titel {
    font-family: "foco";
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1;
    margin-bottom: 10px;
    margin-top: 0;
  }
  section.topNews .newsblock-titel a[href^="/"]:not([href*="://"])::after {
    content: "\00000a" var(--base-url) attr(href);
    white-space: pre;
    text-decoration: none;
    font-size: 0.75em;
  }
  section.topNews .newsblock-content {
    font-weight: 400;
    font-size: 1rem;
    font-family: "foco";
    font-weight: 300;
    line-height: 1.125rem;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  section.topNews .newsblock-link {
    display: none;
  }
  .medicalscore {
    font-family: "foco";
    font-weight: 400;
  }
  .medicalscore label.control-label {
    font-family: "foco";
    font-weight: 700;
    font-size: 1.25rem;
    margin-top: 1rem;
    display: block;
  }
  .card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
  }
  .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  }
  .card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
  }
  .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .mt-5,
  .my-5 {
    margin-top: 3rem!important;
  }
  blockquote {
    margin: 0 0 1rem;
    border-left: 5px solid #eee;
    margin: 0 0 20px;
    padding: 10px 20px;
  }
}
